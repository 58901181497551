<template>
  <section id="inStock" class="in-stock">
    <div id="in-stock__tabs-desk" class="in-stock__tabs">
      <a
        v-for="tab in tabs"
        :href="`#${tab.id}`"
        :key="tab.id"
        class="in-stock__tab"
        :class="{ active: selected_model === tab.id }"
        @click="updateSelectedModel(tab.id)"
      >
        <span>
          <img
            class="in-stock__tab-icon"
            :src="require(`@/img/icons/cars-icons/${tab.id}.png`)"
            :alt="tab.name"
          />
        </span>

        {{ tab.name }}
      </a>
    </div>
    <h4 class="in-stock__tabs-mob__title">Выберите марку</h4>
    <div id="in-stock__tabs-mob" class="in-stock__tabs-mob">
      <span class="in-stock__tabs-mob__icon"
        ><img
          :src="
            require(`@/img/icons/cars-icons/${selected_model.toLowerCase()}.png`)
          "
          alt=""
      /></span>
      <select
        v-model="selected_model"
        class="in-stock__tabs-mob__select"
        name=""
        @change="changeURL"
      >
        <option v-for="tab in tabs" :key="tab.id" :value="tab.id">
          {{ tab.name }}
        </option>
      </select>
    </div>

    <h2 class="in-stock__title heading-2">
      Автомобили <span> {{ model_title }} </span> в наличии
    </h2>
    <div :id="selected_model" class="in-stock__cars">
      <InStockCarCard :cars="handleSelectedModel" />
    </div>
    <a href="#inStock" class="in-stock__top-btn btn-primary">К брендам</a>
  </section>
</template>

<script>
import siteData from "@/config.json";
import InStockCarCard from "./InStockCarCard.vue";

export default {
  components: {
    InStockCarCard,
  },
  data() {
    return {
      tabs: siteData.instock.tabs,
      models: siteData.models,
      selected_model: "lada",
      model_title: "",
      // select_tabs: ["LADA"],
    };
  },
  mounted() {
    this.preSelected();
  },
  methods: {
    updateSelectedModel(model) {
      this.selected_model = model;
    },
    changeURL() {
      window.location.href =
        "#" + this.selected_model.toLowerCase().replace(" ", "-");
    },
    preSelected() {
      //   window.location.href = "#";
      try {
        const href = window.location.href.split("#")[1].toLowerCase();

        if (href) {
          this.selected_model = decodeURI(href);
        } else {
          this.selected_model = "lada";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    handleSelectedModel() {
      const mappedModels = this.models.map((model) => model);
      const filteredModels = mappedModels.filter((model) => {
        if (
          model.brand.toLowerCase().replace(" ", "") === this.selected_model
        ) {
          this.model_title = model.brand;
        }
        return (
          model.brand.toLowerCase().replace(" ", "") == this.selected_model
        );
      });
      return filteredModels;
    },
  },
};
</script>

<style lang="scss" scoped></style>
