<template>
  <section id="map" class="map">
    <div style="height: 100%">
      <yandex-map
        :controls="[]"
        ref="map"
        style="width: 100%; height: 100%"
        :zoom="10"
        :coords="[55.70774, 37.625406]"
        :options="{
          suppressMapOpenBlock: true,
        }"
      >
        <ymap-marker
          :icon="markerIcon"
          :markerId="123"
          :coords="[55.70774, 37.625406]"
        />
      </yandex-map>
    </div>
    <div class="map__contacts__form">
      <form @submit.prevent class="map__form">
        <h2 class="map__form__title">{{ title }}</h2>
        <label class="map__form__label" for="name">Ваше имя</label>
        <input
          placeholder="Имя"
          class="map__form__input"
          type="text"
          name="name"
          id="name"
          v-model="name"
        />
        <label class="map__form__label" for="phone">Ваш телефон</label>
        <input
          v-maska="'+7 (###) ###-##-##'"
          placeholder="Телефон"
          class="map__form__input"
          type="text"
          name="phone"
          id="phone"
          v-model="phoneNumber"
        />
        <div class="map__form__footer">
          <p class="map__form__text">
            Нажимая «Оставить заявку», вы соглашаетесь с
            <a href="#">политикой обработки</a> персональных данных
          </p>
          <button @click="requestCall" class="btn-primary map__form__btn">
            Оставить заявку
          </button>
        </div>
      </form>
    </div>
    <div class="map__contacts">
      <h3 class="map__contacts__address">{{ contacts.address }}</h3>
      <a
        class="map__contacts__contact map__contacts__phone"
        :href="`tel:${contacts.phoneNumber.call}`"
        ><span><img src="@/img/icons/phone-g.svg" alt="" /></span>
        {{ contacts.phoneNumber.text }}</a
      >
      <p class="map__contacts__contact map__contacts__time">
        <span><img src="@/img/icons/clock-g.svg" alt="" /></span>
        {{ contacts.time }}
      </p>
    </div>
  </section>
</template>

<script>
// import axios from "axios";
import siteData from "@/config.json";
export default {
  name: "YandexMapComponent",
  data() {
    return {
      title: "Ответим на все ваши вопросы",
      contacts: siteData.contacts,
      name: "",
      phoneNumber: "",
      detailedControls: { zoomControl: { position: { right: 10, top: 50 } } },
      markerIcon: {
        layout: "default#imageWithContent",
        imageHref: require("@/img/icons/map-marker-g.svg"),
        imageSize: [40, 50],
        imageOffset: [-20, -55],
      },
    };
  },
  methods: {
    validation() {
      // const nameValidation = /^([a-zA-Z ]){3,20}$/;
      // const validName = this.name.match(nameValidation);
      const regexPhoneNumber =
        /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
      const validPhone = this.phoneNumber.match(regexPhoneNumber);
      return this.name && validPhone ? true : false;
    },
    requestCall() {
      if (this.validation()) {
        // // eslint-disable-next-line no-undef
        // ym(91034503, "reachGoal", "Otpravka-form");
        // window.ctw.createRequest(
        //   "atlant",
        //   this.phoneNumber.replace(/\D/g, ""),
        //   [
        //     {
        //       name: "name",
        //       value: this.name,
        //     },
        //   ],
        //   function (success, data) {
        //     console.log(success, data);
        //     if (success) {
        //       console.log(
        //         "Создана заявка на колбек, идентификатор: " +
        //           data.callbackRequestId
        //       );
        //     } else {
        //       console.log("Not sent");
        //     }
        //   }
        // );
        // // const formData = {
        // //   name: this.name,
        // //   phone: this.phoneNumber,
        // //   form_position: this.title,
        // // };
        // // const params = {
        // //   fio: formData.name,
        // //   phoneNumber: formData.phone.replace(/\D/g, ""),
        // //   subject: formData.form_position,
        // //   sessionId: window.ct("calltracking_params", "99pmtl4n").sessionId,
        // // };
        // // const siteId = "55226";
        // // axios
        // //   .get(
        // //     `https://api.calltouch.ru/calls-service/RestAPI/requests/${siteId}/register/`,
        // //     { params }
        // //   )
        // //   .then((response) => {
        // //     console.log(response.status, "Отправка заявки в CallTouch удалась");
        // //   })
        // //   .catch((error) => {
        // //     console.warn(
        // //       `Отправка заявки в CallTouch не удалась. Причина: ${error}.`
        // //     );
        // //   });
        // this.name = "";
        // this.phoneNumber = "";
      }
    },
  },
};
</script>

<style lang="scss">
.yandex-container {
  height: 100%;
}
</style>
