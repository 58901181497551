<template>
  <div class="best-sellers__car">
    <p class="best-sellers__car-gos">Предложение ограничено</p>
    <div class="best-sellers__car-percent">
      <p>{{ calculatePercent(car.price_old, car.price_new) }}%</p>
    </div>

    <div class="best-sellers__car-img">
      <img
        :src="
          require(`@/img/cars/${car.brand.toLowerCase()}/${car.model_id}.png`)
        "
        alt=""
      />
    </div>

    <h4 class="best-sellers__car-name">
      <span>{{ car.brand }}</span> {{ car.model }}
    </h4>
    <div class="in-stock__car__details">
      <p class="in-stock__car-detail">
        Trade-in
        <!-- <span>объем двигателя</span> {{ car.volume }} -->
      </p>
      <p class="in-stock__car-detail">
        Госпрограмма
        <!-- <span>мощность двигателя</span> {{ car.power }} -->
      </p>
      <p class="in-stock__car-detail">
        ОСАГО или КАСКО
        <!-- <span>расход 100 км</span> {{ car.consumption || "-" }} -->
      </p>
      <p class="in-stock__car-detail">
        Рассрочка 0%
        <!-- <span>разгон 0-100</span> {{ car.acceleration || "-" }} -->
      </p>
    </div>
    <div class="best-sellers__car__colors">
      <span class="best-sellers__car-color-text">Цвет: </span>
      <span
        class="best-sellers__car-color best-sellers__car-color-black"
      ></span>
      <span
        class="best-sellers__car-color best-sellers__car-color-white"
      ></span>
      <span class="best-sellers__car-color best-sellers__car-color-red"></span>
      <span class="best-sellers__car-color best-sellers__car-color-blue"></span>
      <span class="best-sellers__car-color best-sellers__car-color-gray"></span>
    </div>
    <hr />

    <div class="best-sellers__car__prices">
      <span class="best-sellers__car-old-price"
        >От {{ priceDecoration(car.price_old) }} ₽</span
      >
      <span class="best-sellers__car-credit"
        >от {{ priceDecoration(car.credit) }} ₽ в месяц</span
      >
      <p class="best-sellers__car-price">
        от {{ priceDecoration(car.price_new) }} ₽
      </p>
    </div>

    <div class="best-sellers__car__buttons">
      <button
        @click="toggleRequestForm($event)"
        class="best-sellers__car__main-btn btn-primary"
      >
        Отправить заявку
      </button>
      <button
        @click="toggleRequestForm($event)"
        class="best-sellers__car__secondary-btn btn-secondary"
      >
        Кредит от 4,9%
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["car", "index"],
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
      });
    },
    priceDecoration(price) {
      return new Intl.NumberFormat("ru", {
        maximumSignificantDigits: 3,
      }).format(price);
    },
    calculatePercent(oldPrice, newPrice) {
      let increase = (newPrice - oldPrice) * 100;
      return parseInt(increase / oldPrice);
    },
  },
};
</script>
