<template>
  <section id="main-slider-1" class="main-swiper-container">
    <swiper
      class="swiper-main"
      :modules="modules"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
    >
      <swiper-slide class="swiper-main-slide">
        <SliderComponent>
          <template #image>
            <img src="../../img/main-slider/01.png" alt="" />
          </template>
          <template #title>
            Распродажа авто 2022 г. <br />
            Скидки до 50%!
          </template>
          <template #subtitle>
            -10% от стоимости по гос. программе <br />
            кредит от 4,9% <br />
            Выгода по trade-in до 260 000 рублей
            <br />Подарки на выбор <br />Полная оплата проезда
          </template>
          <template #sliderButton>
            <button
              @click="toggleRequestForm($event)"
              class="swiper-main-slide__button btn-primary"
            >
              Получить предложение
            </button>
          </template>
        </SliderComponent>
      </swiper-slide>
    </swiper>
  </section>
</template>
<!--  -->
<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import SliderComponent from "./SliderComponent.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    SliderComponent,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
      });
    },
  },
};
</script>
