<template>
  <nav id="navbar" class="navbar">
    <div class="navbar__logo">
      <svg
        width="148"
        height="70"
        viewBox="0 0 148 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0v50.421l4.134.642L105.942 66.81l14.533 2.257-7.883-8.028 30.093 2.77 5.315.487V13.593l-4.442-.41L5.315.486 0 0z"
          class="logo-g_svg__path1"
        ></path>
        <path
          d="M143.776 18.03L5.533 5.334v40.907l101.808 15.747-6.907-7.027 43.342 3.975V18.03zm-107.38 3.385l-9.988-.898L29.49 23.8v17.953l-6.136-.564v-20.98l-6.933-.64v-5.9l20.002 1.847v5.899h-.025zm21.723 22.98l-6.342-20.441-6.342 19.286-6.728-.615 9.141-26.057 7.857.718 9.141 27.724-6.727-.615zm23.083-6.335l-7.112-.642 3.081 3.283v5.437l-6.137-.564V31.366l10.194.923c1.874.18 3.26-1.205 3.26-3.154 0-1.95-1.386-3.59-3.26-3.745l-10.22-.949v-5.745l10.194.924c5.315.487 9.424 4.975 9.424 10.079 0 5.13-4.134 8.848-9.424 8.36zm22.493 10.515l-6.162-.564V21.133l6.162.564v26.878zm22.595 2.077l-5.084-9.31-6.008-.564 3.081 3.283v5.847l-6.162-.564V35.136l10.758 1c1.695.154 3.082-1.205 3.082-3.18 0-1.95-1.387-3.59-3.082-3.744l-10.758-1v-5.745l10.758 1c5.11.461 9.218 4.95 9.218 10.079 0 3.308-2.003 6.104-4.955 7.335l5.726 10.387-6.574-.616z"
          class="logo-g_svg__path2"
          fill="#000"
        ></path>
      </svg>
    </div>
    <div class="navbar__contacts">
      <p class="navbar__contacts__address">
        <span><img src="../../img/icons/map-marker-g.svg" alt="" /></span
        >{{ address }}
      </p>
      <a :href="`tel:${phoneNumber.call}`" class="navbar__contacts__phone">
        <span><img src="../../img/icons/phone-g.svg" alt="" /></span>
        {{ phoneNumber.text }}</a
      >
    </div>
    <div class="navbar__links">
      <a href="#inStock" class="navbar__links-item">Авто в наличии</a
      ><a href="#advantages" class="navbar__links-item">Преимущества</a
      ><a href="#bestSellers" class="navbar__links-item">Хиты продаж</a>
    </div>
    <Transition name="slide">
      <div
        class="navbar__links-mob"
        :class="{ active: burgerShow }"
        v-if="burgerShow"
      >
        <a @click="closeBurger" href="#inStock" class="navbar__links-item"
          >Авто в наличии</a
        ><a @click="closeBurger" href="#advantages" class="navbar__links-item"
          >Преимущества</a
        ><a @click="closeBurger" href="#bestSellers" class="navbar__links-item"
          >Хиты продаж</a
        >
      </div>
    </Transition>
    <button @click="toggleRequestForm" class="navbar__button btn-primary">
      Заказать звонок
    </button>
    <button
      @click="toggleBurger"
      class="navbar__burger-button btn-primary"
      :class="{ active: burgerShow }"
    >
      <div></div>
      <div></div>
      <div></div>
    </button>
  </nav>
</template>

<script>
import siteData from "@/config.json";
export default {
  data() {
    return {
      address: siteData.contacts.address,
      phoneNumber: siteData.contacts.phoneNumber,
      burgerShow: false,
    };
  },
  methods: {
    toggleRequestForm() {
      this.emitter.emit("open-request-form", {
        open: false,
      });
    },
    toggleBurger() {
      this.burgerShow = !this.burgerShow;
    },
    closeBurger() {
      this.burgerShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.3s ease-out;
}

.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
</style>
