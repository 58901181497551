<template>
  <section id="contactForm" class="contact-form">
    <form @submit.prevent class="contact-form__form">
      <h2 v-if="!isSent" class="contact-form__title">{{ title }}</h2>

      <h2 v-if="isSent" class="contact-form__title">Заявка отправлена</h2>

      <input
        placeholder="Имя"
        class="contact-form__input"
        type="text"
        name="name"
        id="name"
        v-model="name"
        :class="{ error: !name && errorStyle }"
      />
      <input
        v-maska="'+7 (###) ###-##-##'"
        placeholder="Телефон"
        class="contact-form__input"
        type="text"
        name="phone"
        id="phone"
        v-model="phoneNumber"
        :class="{ error: !phoneNumber && errorStyle }"
      />

      <button @click="requestCall" class="btn-primary contact-form__btn">
        ЗАКАЗАТЬ ЗВОНОК
      </button>
    </form>
  </section>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      title: "Ответим на все ваши вопросы",
      name: "",
      phoneNumber: "",
      isSent: false,
      errorStyle: false,
    };
  },
  methods: {
    validation() {
      return this.name && this.phoneNumber;
    },
    requestCall() {
      // if (this.validation()) {
      //   // eslint-disable-next-line no-undef
      //   ym(91034503, "reachGoal", "Otpravka-form");
      //   window.ctw.createRequest(
      //     "atlant",
      //     this.phoneNumber.replace(/\D/g, ""),
      //     [
      //       {
      //         name: "name",
      //         value: this.name,
      //       },
      //     ],
      //     function (success, data) {
      //       console.log(success, data);
      //       if (success) {
      //         console.log(
      //           "Создана заявка на колбек, идентификатор: " +
      //             data.callbackRequestId
      //         );
      //       } else {
      //         console.log("Not sent");
      //       }
      //     }
      //   );
      //   // const formData = {
      //   //   name: this.name,
      //   //   phone: this.phoneNumber,
      //   //   form_position: this.title,
      //   // };
      //   // const params = {
      //   //   fio: formData.name,
      //   //   phoneNumber: formData.phone.replace(/\D/g, ""),
      //   //   subject: formData.form_position,
      //   //   sessionId: window.ct("calltracking_params", "99pmtl4n").sessionId,
      //   // };
      //   const siteId = "55226";
      //   // axios
      //   //   .get(
      //   //     `https://api.calltouch.ru/calls-service/RestAPI/requests/${siteId}/register/`,
      //   //     { params }
      //   //   )
      //   //   .then((response) => {
      //   //     console.log(response.status, "Отправка заявки в CallTouch удалась");
      //   //   })
      //   //   .catch((error) => {
      //   //     console.warn(
      //   //       `Отправка заявки в CallTouch не удалась. Причина: ${error}.`
      //   //     );
      //   //   });
      //   this.name = "";
      //   this.phoneNumber = "";
      //   this.isSent = true;
      //   this.errorStyle = false;
      //   this.name = null;
      //   this.phoneNumber = null;
      //   setTimeout(() => (this.isSent = false), 3000);
      // }
    },
  },
};
</script>

<style lang="scss" scoped></style>
