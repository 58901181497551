import { createApp } from "vue";
import App from "./App.vue";
import Maska from "maska";
// yandex maps
import YmapPlugin from "vue-yandex-maps";

// emmiter
import mitt from "mitt";
const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.use(YmapPlugin, {
  apiKey: "",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
});
app.use(Maska);

app.mount("#app");
