<template>
  <section id="second-slider" class="second-swiper-container">
    <swiper
      class="swiper-second"
      :modules="modules"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
    >
      <swiper-slide class="swiper-second-slide">
        <SliderComponent>
          <template #image>
            <img src="../../img/main-slider/02.png" alt="" />
          </template>
          <template #title
            >Не нашли подходящий автомобиль? <br />
            <span> Оставьте заявку на подбор</span>
          </template>
          <template #sliderButton>
            <button
              @click="toggleRequestForm($event)"
              class="swiper-second-slide__button btn-primary"
            >
              Заявка на подбор
            </button>
          </template>
        </SliderComponent>
      </swiper-slide>
    </swiper>
  </section>
</template>
<!--  -->
<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import SliderComponent from "./SliderComponent.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    SliderComponent,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
      });
    },
  },
};
</script>
