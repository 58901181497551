<template>
  <NavbarComponent />
  <MainSliderComponent />
  <CounterComponent />
  <InStockComponent />
  <AdvantagesComponent />
  <ContactFormComponent />
  <BestSellersComponent />
  <SecondSliderComponent />
  <YandexMapComponent />
  <FooterComponent />
  <RequestFormComponent />
</template>

<script>
import NavbarComponent from "./components/Navbar/NavbarComponent.vue";
import MainSliderComponent from "./components/Main-slider/MainSliderComponent.vue";
import CounterComponent from "./components/Counter/CounterComponent.vue";
import InStockComponent from "./components/In-stock/InStockComponent.vue";
import AdvantagesComponent from "./components/Advantages/AdvantagesComponent.vue";
import ContactFormComponent from "./components/Contact-form/ContactFormComponent.vue";
import BestSellersComponent from "./components/Best-sellers/BestSellersComponent.vue";
import SecondSliderComponent from "./components/Second-slider/SecondSliderComponent.vue";
import YandexMapComponent from "./components/yandex-map/YandexMapComponent.vue";
import FooterComponent from "./components/Footer/FooterComponent.vue";
import RequestFormComponent from "./components/Request-form/RequestFormComponent.vue";

export default {
  name: "App",
  components: {
    NavbarComponent,
    MainSliderComponent,
    CounterComponent,
    InStockComponent,
    AdvantagesComponent,
    ContactFormComponent,
    BestSellersComponent,
    SecondSliderComponent,
    YandexMapComponent,
    FooterComponent,
    RequestFormComponent,
  },
};
</script>

<style lang="scss">
@import "./scss/style";
#app {
  @extend .container;
  @include respond-from(tablet) {
    @include container(1.3);
  }
}
</style>
