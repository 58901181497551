<template>
  <section id="request-form">
    <Transition name="fade">
      <div @click="closeModal" v-show="!isHidden" class="modal__bg"></div>
    </Transition>
    <Transition name="slide-fade">
      <form @submit.prevent="submitForm" v-show="!isHidden" class="modal">
        <div @click="closeModal" class="modal__close">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill=""
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#000"
              d="M1.954.04L22.46 20.546l-1.414 1.414L.54 1.454z"
            ></path>
            <path
              fill="#000"
              d="M.54 20.546L21.046.04l1.414 1.414L1.954 21.96z"
            ></path>
          </svg>
        </div>
        <h2 class="modal__title">
          {{ title || "Ответим на все ваши вопросы" }}
        </h2>
        <div class="modal__inputs">
          <label class="modal__label" for="modalName">Ваше имя</label>
          <input
            placeholder="Имя"
            class="modal__input"
            type="text"
            name="modalName"
            id="modalName"
            v-model="name"
          />
          <label class="modal__label" for="modalPhone">Ваш телефон</label>
          <input
            v-maska="'+7 (###) ###-##-##'"
            placeholder="Телефон"
            class="modal__input"
            type="text"
            name="modalPhone"
            id="modalPhone"
            v-model="phoneNumber"
          />
        </div>
        <div class="modal__footer">
          <p class="modal__text">
            Нажимая «Оставить заявку», вы соглашаетесь с
            <a href="#">политикой обработки</a> персональных данных
          </p>
          <button @click="submitForm" class="btn-primary modal__btn">
            Оставить заявку
          </button>
        </div>
      </form>
    </Transition>
  </section>
</template>

<script>
// import axios from "axios";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
export default {
  name: "RequestFormComponent",
  data() {
    return {
      isHidden: true,
      title: "",
      name: "",
      phoneNumber: "",
    };
  },
  methods: {
    validation() {
      // const nameValidation = /^([a-zA-Z ]){3,20}$/;
      // const validName = this.name.match(nameValidation);
      const regexPhoneNumber =
        /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
      const validPhone = this.phoneNumber.match(regexPhoneNumber);
      return this.name && validPhone ? true : false;
    },
    submitForm() {
      if (this.validation()) {
        // eslint-disable-next-line no-undef
        // ym(91034503, "reachGoal", "Otpravka-form");
        // window.ctw.createRequest(
        //   "atlant",
        //   this.phoneNumber.replace(/\D/g, ""),
        //   [
        //     {
        //       name: "name",
        //       value: this.name,
        //     },
        //   ],
        //   function (success, data) {
        //     console.log(success, data);
        //     if (success) {
        //       console.log(
        //         "Создана заявка на колбек, идентификатор: " +
        //           data.callbackRequestId
        //       );
        //     } else {
        //       console.log("Not sent");
        //     }
        //   }
        // );
        // // const formData = {
        // //   name: this.name,
        // //   phone: this.phoneNumber,
        // //   form_position: this.title,
        // // };
        // // const params = {
        // //   fio: formData.name,
        // //   phoneNumber: formData.phone.replace(/\D/g, ""),
        // //   subject: formData.form_position,
        // //   sessionId: window.ct("calltracking_params", "99pmtl4n").sessionId,
        // // };
        // // const siteId = "55226";
        // // axios
        // //   .get(
        // //     `https://api.calltouch.ru/calls-service/RestAPI/requests/${siteId}/register/`,
        // //     { params }
        // //   )
        // //   .then((response) => {
        // //     console.log(response.status, "Отправка заявки в CallTouch удалась");
        // //   })
        // //   .catch((error) => {
        // //     console.warn(
        // //       `Отправка заявки в CallTouch не удалась. Причина: ${error}.`
        // //     );
        // //   });
        // this.isHidden = true;
        // this.name = "";
        // this.phoneNumber = "";
        // enablePageScroll();
      }
    },
    closeModal() {
      this.isHidden = true;
      enablePageScroll();
    },
  },
  mounted() {
    this.emitter.on("open-request-form", (data) => {
      this.isHidden = data.open;
      this.title = data.title;
      return !data.open ? disablePageScroll() : enablePageScroll();
    });
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
