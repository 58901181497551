<template>
  <div v-for="car in cars" :key="car.id" class="in-stock__car">
    <div class="in-stock__car-img">
      <img
        :src="
          require(`@/img/cars/${car.brand.toLowerCase()}/${car.model_id}.png`)
        "
        alt=""
      />
    </div>
    <span class="in-stock__car__best-seller" v-if="car.best_seller"
      ><img src="@/img/icons/fire.png" alt="" width="30" height="30"
    /></span>
    <h4 class="in-stock__car-name">
      <span>{{ car.brand }} </span> {{ car.model }}
    </h4>
    <div class="in-stock__car__details">
      <p class="in-stock__car-detail">
        Trade-in
        <!-- <span>объем двигателя</span> {{ car.volume }} -->
      </p>
      <p class="in-stock__car-detail">
        Госпрограмма
        <!-- <span>мощность двигателя</span> {{ car.power }} -->
      </p>
      <p class="in-stock__car-detail">
        ОСАГО или КАСКО
        <!-- <span>расход 100 км</span> {{ car.consumption || "-" }} -->
      </p>
      <p class="in-stock__car-detail">
        Рассрочка 0%
        <!-- <span>разгон 0-100</span> {{ car.acceleration || "-" }} -->
      </p>
    </div>
    <div class="in-stock__car__colors">
      <span class="in-stock__car-color-text">Цвет: </span>
      <span class="in-stock__car-color in-stock__car-color-black"></span>
      <span class="in-stock__car-color in-stock__car-color-white"></span>
      <span class="in-stock__car-color in-stock__car-color-red"></span>
      <span class="in-stock__car-color in-stock__car-color-blue"></span>
      <span class="in-stock__car-color in-stock__car-color-gray"></span>
    </div>
    <hr />
    <div class="in-stock__car__prices">
      <span class="in-stock__car-old-price"
        >От {{ priceDecoration(car.price_old) }} ₽</span
      >
      <span class="in-stock__car-credit"
        >от {{ priceDecoration(car.credit) }} ₽ в месяц</span
      >
      <p class="in-stock__car-price">
        от {{ priceDecoration(car.price_new) }} ₽
      </p>
    </div>
    <div class="in-stock__car__buttons">
      <button
        @click="toggleRequestForm($event)"
        class="in-stock__car__main-btn btn-primary"
      >
        Отправить заявку
      </button>
      <button
        @click="toggleRequestForm($event)"
        class="in-stock__car__secondary-btn btn-secondary"
      >
        Кредит от 4,9%
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cars"],
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
      });
    },
    priceDecoration(price) {
      return new Intl.NumberFormat("ru", {
        maximumSignificantDigits: 6,
      }).format(price);
    },
  },
};
</script>

<style lang="scss" scoped></style>
