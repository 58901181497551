<template>
  <section id="counter" class="newCounter">
    <Countdown
      id="counter-desk"
      mainColor="#000000"
      labelColor="#000000"
      secondFlipColor="#000000"
      mainFlipBackgroundColor="#DCDCDC"
      secondFlipBackgroundColor="#DCDCDC"
      :deadline="setNextDate"
      labelSize="2.5rem"
      countdownSize="6rem"
      :labels="{
        days: 'дня',
        hours: 'часов',
        minutes: 'минут',
        seconds: 'секунд',
      }"
    />
    <Countdown
      id="counter-mob"
      mainColor="#000000"
      labelColor="#000000"
      secondFlipColor="#000000"
      mainFlipBackgroundColor="#DCDCDC"
      secondFlipBackgroundColor="#DCDCDC"
      :deadline="setNextDate"
      labelSize="1.2rem"
      countdownSize="3.4rem"
      :labels="{
        days: 'дня',
        hours: 'часов',
        minutes: 'минут',
        seconds: 'секунд',
      }"
    />
  </section>
</template>

<!-- :deadline="setNextDate" for auto time refresh-->

<script>
import { Countdown } from "vue3-flip-countdown";
export default {
  components: { Countdown },
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      const formattedDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      return formattedDate;
    },
    getNow() {
      //making new date each time when visiting the website
      const nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 3);
      let date = this.formatDate(nextDate);
      let dateTime = date;

      //checking for local storage and setting dateTime to it
      if (localStorage.getItem("timer") === null) {
        localStorage.setItem("timer", dateTime);
      }
      if (localStorage.getItem("timer")) {
        dateTime = localStorage.getItem("timer");
      }

      //here we check if the local storage time is smaller than
      // today so we can run the function again and set new time
      if (new Date(dateTime) - new Date(date) <= 0) {
        nextDate.setDate(nextDate.getDate() + 2);
        date = this.formatDate(nextDate);
        dateTime = date;
        localStorage.setItem("timer", dateTime);
        return dateTime;
      }
      return dateTime;
    },
  },
  computed: {
    setNextDate() {
      return this.getNow();
    },
  },
};
</script>

<style lang="scss"></style>
