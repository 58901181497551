<template>
  <section id="advantages" class="advantages">
    <h3 class="advantages__title heading-2">Преимущества</h3>
    <div class="advantages__cards">
      <div class="advantages__card">
        <img
          src="@/img/icons/advantages/office-g.svg"
          alt=""
          class="advantages__card-img"
        />
        <p class="advantages__card-text">
          Более 20 брендов новых авто в одном месте
        </p>
      </div>
      <div class="advantages__card">
        <img
          src="@/img/icons/advantages/clock-g.svg"
          alt=""
          class="advantages__card-img"
        />
        <p class="advantages__card-text">Экспресс-кредит за 15 минут</p>
      </div>
      <div class="advantages__card">
        <img
          src="@/img/icons/advantages/car-g.svg"
          alt=""
          class="advantages__card-img"
        />
        <p class="advantages__card-text">Полная оплата проезда</p>
      </div>
      <div class="advantages__card">
        <img
          src="@/img/icons/advantages/reverse-g.svg"
          alt=""
          class="advantages__card-img"
        />
        <p class="advantages__card-text">Выгодный trade-in</p>
      </div>

      <div class="advantages__card">
        <img
          src="@/img/icons/advantages/hand-g.svg"
          alt=""
          class="advantages__card-img"
        />
        <p class="advantages__card-text">Подарки на выбор при покупке</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
