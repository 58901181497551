<template>
  <section id="footer" class="footer">
    <p class="footer__text">{{ text.text_1 }}</p>
    <p class="footer__text">{{ text.text_2 }}</p>
    <p class="footer__text">{{ text.text_3 }}</p>
    <p class="footer__text">{{ text.text_4 }}</p>
  </section>
</template>

<script>
import siteData from "@/config.json";
export default {
  data() {
    return {
      text: siteData.footer,
    };
  },
};
</script>
