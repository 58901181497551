<template>
  <div class="swiper-second-slide__image">
    <slot name="video"></slot>
    <slot name="image"></slot>
  </div>
  <div class="swiper-second-slide__content">
    <h2 class="swiper-second-slide__title heading-1">
      <slot name="title"></slot>
    </h2>

    <slot name="sliderButton"></slot>
  </div>
</template>

<script>
export default {
  name: "SliderComponent",
  data() {
    return {};
  },
};
</script>
