<template>
  <section id="bestSellers" class="best-sellers">
    <h3 class="best-sellers__title heading-2">
      Хиты продаж <span><img src="@/img/icons/fire.png" alt="" /></span>
    </h3>
    <div class="best-sellers__swiper">
      <swiper
        :modules="modules"
        :slides-per-view="3"
        :space-between="25"
        :navigation="swiperOptions.navigation"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true,
        }"
        :breakpoints="swiperOptions.breakpoints"
      >
        <swiper-slide
          v-for="(car, index) in handleBestSellers"
          :key="index"
          class="best-sellere__slide"
        >
          <BestSellersCard :car="car" :index="index" />
        </swiper-slide>
      </swiper>
      <button class="prev-slide-best-sellers"></button>
      <button class="next-slide-best-sellers"></button>
    </div>
    <div class="best-sellers__mob">
      <div v-for="(car, index) in handleBestSellers" :key="index">
        <BestSellersCard :car="car" :index="index" />
      </div>
    </div>
  </section>
</template>
<!--  -->
<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import BestSellersCard from "./BestSellersCard.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    BestSellersCard,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      cars: siteData.bestsellers,
      models: siteData.models,
      swiperOptions: {
        navigation: {
          nextEl: ".next-slide-best-sellers",
          prevEl: ".prev-slide-best-sellers",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
          1240: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  computed: {
    handleBestSellers() {
      const mappedModels = this.models.map((model) => model);
      const filteredModels = mappedModels.filter((model) => model.best_seller);
      return filteredModels;
    },
  },
};
</script>
